import { lazy } from "react";

export const Home = lazy(() => import("../pages/Home"));
export const CalendarDetails = lazy(() => import("../components/calender/CalendarDetails"));
export const AnnouncementDetail = lazy(() => import("../components/announcement/announcementDetail"));
export const AnnouncementLists = lazy(() => import("../components/announcement/announcementLists"));
export const ViewAllDiscussions = lazy(() => import("../components/discussions/viewAllDiscussions"));
export const NewsDetails = lazy(() => import("../components/news/newsDetails"));
export const NewsLists = lazy(() => import("../components/news/newsLists"));
export const PollLists = lazy(() => import("../components/survey/pollLists"));
export const EventLists = lazy(() => import("../components/events/eventLists"));
export const EventDetail = lazy(() => import("../components/events/EventDetail"));
export const Initiatives = lazy(() => import("../components/initiatives/Initiatives"));
export const Login = lazy(() => import("../pages/Login"));
export const NotFound = lazy(() => import("../pages/NotFound"));
export const TrainingList = lazy(() => import("../components/training/TrainingList"));
export const VacancyDetails = lazy(() => import("../components/InternalVacancy/internalVacancyDetails"));
export const BenefitDetails = lazy(() => import("../components/benefits/benefitDetails"));
export const BenefitList = lazy(() => import("../components/benefits/benefitList"));
export const VaccancyLists = lazy(() => import("../components/InternalVacancy/VaccancyLists"));
export const Activities = lazy(() => import("../components/activities/activitiesList"));
export const Gallery = lazy(() => import("../components/gallery/galleryList"));
export const Leaderboard = lazy(() => import("../components/leaderBoard/leaderboard"));
export const DiscussionDetails = lazy(() => import("../components/discussions/discussionDetails"));
export const TrainingDetails = lazy(() => import("../components/training/trainigDetails"));
export const AlertList = lazy(() => import("../components/alert/alertList"));
export const NotificationList = lazy(() => import("../components/notification/notificationList"));
export const ActivityDetails = lazy(() => import("../components/activities/activityDetail"));
export const SearchResult = lazy(() => import("../components/search/searchResult"));
export const About = lazy(() => import("../components/about/about"));
export const Rules = lazy(() => import("../components/rules/rules"));
export const FaqList = lazy(() => import("../components/faq/faqList"));
export const InitiativeDetails = lazy(() => import("../components/initiatives/initiativeDetail"));

