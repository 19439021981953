import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from '../utils/PrivateRoutes';
import {
  Home,
  CalendarDetails,
  ViewAllDiscussions,
  NewsDetails,
  NewsLists,
  PollLists,
  AnnouncementLists,
  AnnouncementDetail,
  EventLists,
  EventDetail,
  Initiatives,
  Login,
  NotFound,
  TrainingList,
  VacancyDetails,
  BenefitDetails,
  BenefitList,
  VaccancyLists,
  Activities,
  Gallery,
  Leaderboard,
  DiscussionDetails,
  TrainingDetails,
  AlertList,
  NotificationList,
  ActivityDetails,
  SearchResult,
  About,
  Rules,
  FaqList,
  InitiativeDetails,
} from "./pageListAsync";

const RouteHandler = () => {
  return (
    <>
      <Suspense fallback={<div id="fullLoader"></div>}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route index element={<Home />} />
            <Route path="/calendar" element={<CalendarDetails />} />
            <Route path="/newsLists" element={<NewsLists />} />
            <Route path="/news-details/:id" element={<NewsDetails />} />
            <Route path="/pollLists" element={<PollLists />} />
            <Route path="/announcements" element={<AnnouncementLists />} />
            <Route
              path="/announcement-details/:id"
              element={<AnnouncementDetail />}
            />
            <Route path="/events" element={<EventLists />} />
            <Route path="/event-details/:id" element={<EventDetail />} />
            <Route path="/internalvaccancy" element={<VaccancyLists />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/training" element={<TrainingList />} />
            <Route path="/training-details/:id" element={<TrainingDetails />} />
            <Route path="/initiatives" element={<Initiatives />} />
            <Route
              path="/initiatives-details/:id"
              element={<InitiativeDetails />}
            />
            <Route path="/benefits" element={<BenefitList />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/activity-details/:id" element={<ActivityDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/discussions" element={<ViewAllDiscussions />} />
            <Route
              path="/discussions-details/:id"
              element={<DiscussionDetails />}
            />
            <Route path="/alert" element={<AlertList />} />
            <Route
              path="/internalvaccancy-details/:id"
              element={<VacancyDetails />}
            />
            <Route path="/benefit-details/:id" element={<BenefitDetails />} />
            <Route path="/notification" element={<NotificationList />} />
            <Route path="/search" element={<SearchResult />} />
            <Route path="/faq" element={<FaqList />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouteHandler;
