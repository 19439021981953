import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { getDashboardItems, getMenuText } from '../redux/actionCreator'

function PrivateRoutes() {

  const dispatch = useDispatch()
  const token = localStorage.getItem('token')



  // let auth = {'token':token}
  let auth = { 'token': token }

  return (
    auth.token
      ? <Outlet />
      : <Navigate to='/login' />
  )
}

export default PrivateRoutes