import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        newCommentShow : false,
        training_is_joined : false,
        vacancy_is_applied : false,
        activity_is_joined : false,
        event_is_applied : false,
        discussionUserList : [],
        // happiness_status : true,
        newsUnit :  {
            _id: "",
            unit_name: "All",
        },
        activityUnit : {
            _id: "",
            unit_name: "All",
        },
        vacancyUnit : {
            _id: "",
            unit_name: "All",
        },
        initiativeUnit : {
            _id: "",
            unit_name: "All",
        },
        eventUnit : {
            _id: "",
            unit_name: "All",
        },
        benefitUnit : {
            _id: "",
            unit_name: "All",
        },
        announcementUnit : {
            _id: "",
            unit_name: "All",
        },
        trainingUnit : {
            _id: "",
            unit_name: "All",
        },
        discussionCommentShow : false,
        unit_details : [],
        discussionUserList : [],
        translate_details : [],
        fullLoader : false,
        leaderboardUnit :  {
            _id: "",
            unit_name: "All",
        },
        leaderboardModule : {
            key: "",
            value: "All",
        },
        initiativesPath : "initiatives",
        suggestionSubmitLoader : false
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})

export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const permission = (state ) => state.commonReducer.permission;
export const permissionModules = (state ) => state.commonReducer.permissionModules;
export const department_details = (state ) => state.commonReducer.department_details;
export const unit_details = (state ) => state.commonReducer.unit_details;
export const homeNewsList = (state ) => state.commonReducer.homeNewsList;
export const newsPath = (state ) => state.commonReducer.newsPath;
export const all_news_list = (state ) => state.commonReducer.all_news_list;
export const allNewsPath = (state ) => state.commonReducer.allNewsPath;
export const news_details = (state ) => state.commonReducer.news_details;
export const news_details_path = (state ) => state.commonReducer.news_details_path; 
export const homeAnnouncementList = (state ) => state.commonReducer.homeAnnouncementList;
export const announcementPath = (state ) => state.commonReducer.announcementPath;
export const announcement_details = (state ) => state.commonReducer.announcement_details;
export const announcement_details_path = (state ) => state.commonReducer.announcement_details_path;
export const all_announcement_list = (state ) => state.commonReducer.all_announcement_list;
export const allAnnouncementPath = (state ) => state.commonReducer.allAnnouncementPath;
export const homeEventList = (state ) => state.commonReducer.homeEventList;
export const eventPath = (state ) => state.commonReducer.eventPath;
export const all_event_list = (state ) => state.commonReducer.allEventList;
export const allEventPath = (state ) => state.commonReducer.allEventPath;
export const event_details = (state ) => state.commonReducer.event_details;
export const event_details_path = (state ) => state.commonReducer.event_details_path;
export const discussionUserList = (state ) => state.commonReducer.discussionUserList;
export const discussion_list = (state ) => state.commonReducer.discussion_list;
export const discussion_path = (state ) => state.commonReducer.discussion_path;
export const eventList = (state ) => state.commonReducer.eventList;
export const polls_list = (state ) => state.commonReducer.polls_list;
export const pollsCount = (state ) => state.commonReducer.pollsCount;
export const homeTrainingList = (state ) => state.commonReducer.homeTrainingList;
export const all_training_list = (state ) => state.commonReducer.all_training_list;
export const allTrainingPath = (state ) => state.commonReducer.allTrainingPath;
export const training_details = (state ) => state.commonReducer.training_details;
export const userUnitLists = (state ) => state.commonReducer.userUnitLists;
export const homeScrollList = (state ) => state.commonReducer.homeScrollList;
export const alert_path = (state ) => state.commonReducer.alert_path;
export const allNotificationList = (state ) => state.commonReducer.allNotificationList;
export const all_alert_path = (state ) => state.commonReducer.all_alert_path;
export const quick_access_list = (state ) => state.commonReducer.quick_access_list;
export const quick_access_path = (state ) => state.commonReducer.quick_access_path;
export const vacancy_list = (state ) => state.commonReducer.vacancy_list;
export const vacancy_details = (state ) => state.commonReducer.vacancy_details;
export const all_vacancy_list = (state ) => state.commonReducer.all_vacancy_list;
export const benefit_list = (state ) => state.commonReducer.benefit_list;
export const all_benefit_list = (state ) => state.commonReducer.all_benefit_list;
export const benefit_details = (state ) => state.commonReducer.benefit_details;
export const activitiesHome = (state ) => state.commonReducer.activitiesHome;
export const allActivities = (state ) => state.commonReducer.allActivities;
export const initiativesHome = (state ) => state.commonReducer.initiativesHome;
export const allInitiatives = (state ) => state.commonReducer.allInitiatives;
export const intiative_details = (state ) => state.commonReducer.intiative_details;
export const faq_list = (state ) => state.commonReducer.faq_list;
export const dashboard_list = (state ) => state.commonReducer.dashboard_list;
export const webLogoPath = (state ) => state.commonReducer.webLogoPath;
export const gallery_list = (state ) => state.commonReducer.gallery_list;
export const gallery_Path = (state ) => state.commonReducer.gallery_Path;
export const all_gallery_list = (state ) => state.commonReducer.all_gallery_list;
export const leaderboard_details = (state ) => state.commonReducer.leaderboard_details;
export const home_evaluations = (state ) => state.commonReducer.home_evaluations;
export const current_user_leaderboard_details = (state ) => state.commonReducer.current_user_leaderboard_details;
export const evaluations_list = (state ) => state.commonReducer.evaluations_list;
export const evaluation_details = (state ) => state.commonReducer.evaluation_details;
export const modules = (state ) => state.commonReducer.modules;
export const newCommentShow = (state ) => state.commonReducer.newCommentShow;
export const training_is_joined = (state ) => state.commonReducer.training_is_joined;
export const discussion_details = (state ) => state.commonReducer.discussion_details;
export const discussion_details_path = (state ) => state.commonReducer.discussion_details_path;
export const allScrollList = (state ) => state.commonReducer.allScrollList;
export const alert_total_count = (state ) => state.commonReducer.alert_total_count;
export const homeNotificationList = (state ) => state.commonReducer.homeNotificationList;
export const homeNotificationCount = (state ) => state.commonReducer.homeNotificationCount;
export const event_is_applied = (state ) => state.commonReducer.event_is_applied;
export const allNotificationTotalPages = (state ) => state.commonReducer.allNotificationTotalPages;
export const activity_details = (state ) => state.commonReducer.activity_details;
export const activity_is_joined = (state ) => state.commonReducer.activity_is_joined;
export const vacancy_is_applied = (state ) => state.commonReducer.vacancy_is_applied;
export const searchResult = (state ) => state.commonReducer.searchResult;
export const about = (state ) => state.commonReducer.about;
export const about_details = (state ) => state.commonReducer.about_details;
export const rules = (state ) => state.commonReducer.rules;
export const rules_details = (state ) => state.commonReducer.rules_details;
export const faq_home = (state ) => state.commonReducer.faq_home;
export const happiness_status = (state ) => state.commonReducer.happiness_status;
export const happiness = (state ) => state.commonReducer.happiness;
export const newsUnit = (state ) => state.commonReducer.newsUnit;
export const activityUnit = (state ) => state.commonReducer.activityUnit;
export const vacancyUnit = (state ) => state.commonReducer.vacancyUnit;
export const initiativeUnit = (state ) => state.commonReducer.initiativeUnit;
export const eventUnit = (state ) => state.commonReducer.eventUnit;
export const benefitUnit = (state ) => state.commonReducer.benefitUnit;
export const announcementUnit = (state ) => state.commonReducer.announcementUnit;
export const trainingUnit = (state ) => state.commonReducer.trainingUnit;
export const allNewsTotalPages = (state ) => state.commonReducer.allNewsTotalPages;
export const allEventTotalPages = (state ) => state.commonReducer.allEventTotalPages;
export const allAnnouncementTotalPages = (state ) => state.commonReducer.allAnnouncementTotalPages;
export const allActivitiesTotalPages = (state ) => state.commonReducer.allActivitiesTotalPages;
export const allTrainingTotalPages = (state ) => state.commonReducer.allTrainingTotalPages;
export const allVacancyTotalpages = (state ) => state.commonReducer.allVacancyTotalpages;
export const allInitiativesTotalPages = (state ) => state.commonReducer.allInitiativesTotalPages;
export const allGalleryTotalPages = (state ) => state.commonReducer.allGalleryTotalPages;
export const allFaqTotalPages = (state ) => state.commonReducer.allFaqTotalPages;
export const discussionTotalPages = (state ) => state.commonReducer.discussionTotalPages;
export const allBenefitTotalPages = (state ) => state.commonReducer.allBenefitTotalPages;
export const leaderboardTotalpages = (state ) => state.commonReducer.leaderboardTotalpages;
export const initiativesPath = (state ) => state.commonReducer.initiativesPath;
export const username = (state ) => state.commonReducer.username;
export const translate_details = (state ) => state.commonReducer.translate_details;
export const eventTotalPages = (state ) => state.commonReducer.eventTotalPages;
export const employee_id = (state ) => state.commonReducer.employee_id;
export const discussionCommentShow = (state ) => state.commonReducer.discussionCommentShow;
export const initiativePath = (state ) => state.commonReducer.initiativePath;
export const benefitPath = (state ) => state.commonReducer.benefitPath;
export const rulePath = (state ) => state.commonReducer.rulePath;
export const rulePages = (state ) => state.commonReducer.rulePages;
export const fullLoader = (state ) => state.commonReducer.fullLoader;

export const leaderboard_modules = (state ) => state.commonReducer.leaderboard_modules;
export const leaderboardUserUnitLists = (state ) => state.commonReducer.leaderboardUserUnitLists;
export const leaderboardUnit = (state ) => state.commonReducer.leaderboardUnit;
export const leaderboardModule = (state ) => state.commonReducer.leaderboardModule;
export const suggestionSubmitLoader = (state ) => state.commonReducer.suggestionSubmitLoader;
// export const fullLoader = (state ) => state.commonReducer.fullLoader;
// export const discussionCommentShow = (state ) => state.commonReducer.discussionCommentShow;
// export const initiativePath = (state ) => state.commonReducer.initiativePath;
// export const benefitPath = (state ) => state.commonReducer.benefitPath;
// export const rulePath = (state ) => state.commonReducer.rulePath;
// export const rulePages = (state ) => state.commonReducer.rulePages;
// export const fullLoader = (state ) => state.commonReducer.fullLoader;

export default commonSlice.reducer;