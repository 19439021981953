import axios from 'axios';
import { updateRedux } from './commonReducer';
import { API_BASE_URL } from "../constants/configuration";
import { toast } from 'react-toastify';
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';

export const insertLoginData = (data, callback) => (dispatch) => {
  axios.post(`${API_BASE_URL}web/login`, data).then(res => {
    if (res.data.status) {
      localStorage.setItem('username', res.data?.username);
      localStorage.setItem('token', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);
      localStorage.setItem('employee_id', res.data.employee_id);
      dispatch(updateRedux({ key: "employee_id", value: res.data?.employee_id }))
      dispatch(updateRedux({ key: "username", value: res.data?.username }))
      callback && callback(true, res.data.message)
    } else {
      callback && callback(false, res.data.message)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertManualLoginData = (data, callback) => () => {
  axios.post(`${API_BASE_URL}web/manualLogin`, data).then(res => {
    if (res.data.login_type == 2) {
      callback(res)
    } else if (res.data.login_type == 1) {
      callback(res)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertNewLoginData = (data, callback) => (dispatch) => {
  axios.post(`${API_BASE_URL}web/manualNewPasswordUpdation`, data).then(res => {
    if (res.data.status) {
      localStorage.setItem('username', res.data?.username);
      localStorage.setItem('token', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);
      localStorage.setItem('employee_id', res.data.employee_id);
      dispatch(updateRedux({ key: "employee_id", value: res.data?.employee_id }))
      dispatch(updateRedux({ key: "username", value: res.data?.username }))
      if (callback) {
        callback(res.data)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const verifyLoginpassword = (data, callback) => (dispatch) => {
  axios.post(`${API_BASE_URL}web/manualPasswordVerification`, data).then(res => {
    if (res.data.status) {
      localStorage.setItem('username', res.data?.username);
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('refreshToken', res.data.refreshToken)
      localStorage.setItem('employee_id', res.data.employee_id);
      dispatch(updateRedux({ key: "employee_id", value: res.data?.employee_id }))
      dispatch(updateRedux({ key: "username", value: res.data?.username }))
      if (callback) {
        callback()
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getModulePermission = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getRoleUserPermissions`).then((res) => {
    if (res.data.status) {
      localStorage.setItem("permission", res.data.modules?.permissions);
      dispatch(updateRedux({ key: "permissionModules", value: res.data.modules }))
      if (callback) {
        callback()
      }
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getDepartment = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getUnit`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "unit_details", value: res.data.unit_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getUnit = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getUnit`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "unit_details", value: res.data.unit_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeNews = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getNews`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeNewsList", value: res.data.news_list }))
      dispatch(updateRedux({ key: "newsPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getNewsDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getNews/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "news_details", value: res.data.news_details }))
      dispatch(updateRedux({ key: "news_details_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllNews = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllNews`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_news_list", value: res.data.news_list }))
      dispatch(updateRedux({ key: "allNewsPath", value: res.data.path }))
      dispatch(updateRedux({ key: "allNewsTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getNewsLikeCount = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getNewsLikeCount/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "news_like_count", value: res.data.news_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const removeNewsLike = (news_id, callback) => (dispatch) => {
  const data = {
    news_id: news_id
  };
  axiosInstance.post(`${API_BASE_URL}web/removeNewsFromLikeList`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback()
      }
    } else {

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const addNewsLike = (news_id, callback) => (dispatch) => {
  const data = {
    news_id: news_id
  };
  axiosInstance.post(`${API_BASE_URL}web/addNewsToLikeList`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback()
      }
    } else {

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const addNewsComment = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/addNewsComment`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback()
      }
    } else {

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getHomeAnnouncement = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAnnouncement`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeAnnouncementList", value: res.data.announcement_list }))
      dispatch(updateRedux({ key: "announcementPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getAnnouncementDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getAnnouncement/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "announcement_details", value: res.data.announcement_details }))
      dispatch(updateRedux({ key: "announcement_details_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllAnnouncement = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllAnnouncement`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_announcement_list", value: res.data.announcement_list }))
      dispatch(updateRedux({ key: "allAnnouncementPath", value: res.data.path }))
      dispatch(updateRedux({ key: "allAnnouncementTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const addAnnouncementComment = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/addAnnouncementComment`, data).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertSuggestion = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}web/submitSuggestion`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(true)
      }
    } else {
      console.log(res.data)
      if (callback) {
        callback(false)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getHomeEvent = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getEvents`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeEventList", value: res.data.event_list }))
      dispatch(updateRedux({ key: "eventPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const searchDiscussionUser = (formData) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}web/searchDiscussionsUsers`, formData).then((res) => {
    if (res.data.status) {
      if (res.data.users?.length) {
        let arr = []
        arr = res.data.users?.map(ele => {
          let obj = { ...ele }
          obj.label = ele.name
          obj.value = ele._id
          return obj
        })
        dispatch(updateRedux({ key: "discussionUserList", value: arr }))
      } else {
        dispatch(updateRedux({ key: "discussionUserList", value: [] }))
      }
      // dispatch(updateRedux({ key: "discussionUserList", value: res.data.users }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getAllEvents = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllEvents`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "allEventList", value: res.data.event_list }))
      dispatch(updateRedux({ key: "allEventPath", value: res.data.path }))
      dispatch(updateRedux({ key: "allEventTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getEventDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getEvent/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "event_details", value: res.data.event_details }))
      dispatch(updateRedux({ key: "event_is_applied", value: res.data.is_applied }))
      dispatch(updateRedux({ key: "event_details_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(true)
      }
    } else {
      console.log(res.data)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const insertDiscussion = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}web/insertDiscussion`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      let data = {
        page: ""
      }
      dispatch(getDiscussion(data))
      if (callback) {
        callback(true)
      }
    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const addEventComment = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/addEventComment`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback()
      }
    } else {

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getDiscussion = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllDiscussions`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "discussion_list", value: res.data.discussion_list }))
      dispatch(updateRedux({ key: "discussion_path", value: res.data.path }))
      dispatch(updateRedux({ key: "discussionTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getDiscussionDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getDiscussionDetail/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "discussion_details", value: res.data.discussion_details }))
      dispatch(updateRedux({ key: "discussion_details_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}


export const addDiscussionComment = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/addDiscussionComment`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback()
      }
    } else {

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const joinEvent = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/applyEvent`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))

      // let value = {
      //   page: 0,
      //   type: "user",
      //   limit: 10
      // }
      // dispatch(getLeaderBoard(value))
      if (callback) {
        callback(true)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const addUserCalenderEvent = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/addCalenderEvent`, data).then((res) => {
    if (res.data.status) {
      // let data = {
      //   start : "",
      //   end : ""
      // }
      // dispatch(getCalenderEvents(data))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(res.data.status)
      }

    } else {
      console.log("err")
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getCalenderEvents = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getCalenderEvents`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "eventList", value: res.data.events }))
      dispatch(updateRedux({ key: "eventTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(true)
      }
    } else {
      console.log("err")
      if (callback) {
        callback(false)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateUserCalenderEvent = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/updateCalenderEvent`, data).then((res) => {
    if (res.data.status) {
      // let data = {
      //   start : "",
      //   end : ""
      // }
      // dispatch(getCalenderEvents(data))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(res.data.status)
      }
    } else {
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteUserCalenderEvent = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/deleteCalenderEvent`, data).then((res) => {
    if (res.data.status) {
      // let data = {
      //   start : "",
      //   end : ""
      // }
      // dispatch(getCalenderEvents(data))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getPollsData = (callback) => async (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getPolls`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "polls_list", value: res.data.polls }))
      dispatch(updateRedux({ key: "pollsCount", value: res.data.pollsCount }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const answerPoll = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}web/answerPolls`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(true, res.data.message)
      }

    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getHomeTraining = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getHomeTraining`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeTrainingList", value: res.data.training_list }))
      dispatch(updateRedux({ key: "trainingPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getAllTraining = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllTraining`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_training_list", value: res.data.training_list }))
      dispatch(updateRedux({ key: "allTrainingPath", value: res.data.path }))
      dispatch(updateRedux({ key: "allTrainingTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const getTrainingDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getTraining/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "training_details", value: res.data.training_details }))
      dispatch(updateRedux({ key: "training_is_joined", value: res.data.is_joined }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const applyTraining = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/applyTraining`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      // let value = {
      //   page: 0,
      //   type: "user",
      //   limit: 10
      // }
      // dispatch(getLeaderBoard(value))
      if (callback) {
        callback(true, res.data.message)
      }
    } else {
      if (callback) {
        callback(false, res.data.message)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserUnit = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getUserUnits`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "userUnitLists", value: res.data.units }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeScroll = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getHomeScroll`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeScrollList", value: res.data.scroll }))
      dispatch(updateRedux({ key: "alert_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllScroll = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllScroll`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "allScrollList", value: res.data.scroll }))
      dispatch(updateRedux({ key: "all_alert_path", value: res.data.path }))
      dispatch(updateRedux({ key: "alert_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getScrollDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getScroll/${id}`).then((res) => {
    if (res.data.status) {
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllNotification = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllNotifications`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "allNotificationList", value: res.data.notification }))
      dispatch(updateRedux({ key: "allNotificationTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "initiativesPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeNotification = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getNotifications`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeNotificationList", value: res.data.notification }))
      dispatch(updateRedux({ key: "homeNotificationCount", value: res.data.notification_count }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const deleteNotification = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/removeNotification`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      dispatch(getHomeNotification())
      dispatch(getAllNotification())
      callback && callback(true, res.data.message)
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const deleteAllNotification = (callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/removeAllNotifications`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      dispatch(getHomeNotification())
      dispatch(getAllNotification())
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback()
      }
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllQuickAccess = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllQuickAccess`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "quick_access_list", value: res.data.quick_access_list }))
      dispatch(updateRedux({ key: "quick_access_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeVacancy = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getHomeVecancy`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "vacancy_list", value: res.data.vecancy_list }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getVacancyDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getVecancy/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "vacancy_details", value: res.data.vecancy_details }))
      dispatch(updateRedux({ key: "vacancy_is_applied", value: res.data.is_applied }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const joinVacancy = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/applyVecancy`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))

      // let value = {
      //   page: 0,
      //   type: "user",
      //   limit: 10
      // }
      // dispatch(getLeaderBoard(value))
      if (callback) {
        callback(true, 'Applied successfully')
      }
    } else {
      if (callback) {
        callback(false, res.data.message)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getAllVacancy = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllVecancy`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_vacancy_list", value: res.data.vecancy_list }))
      dispatch(updateRedux({ key: "allVacancyTotalpages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeBenefit = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getBenefits`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "benefit_list", value: res.data.benefits }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllBenefit = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllBenefits`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_benefit_list", value: res.data.benefits }))
      dispatch(updateRedux({ key: "allBenefitTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getBenefitDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getBenefit/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "benefit_details", value: res.data.benefits }))
      dispatch(updateRedux({ key: "benefitPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeActivities = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getActivities`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "activitiesHome", value: res.data.activities }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllActivities = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllActivities`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "allActivities", value: res.data.activities }))
      dispatch(updateRedux({ key: "allActivitiesTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const joinActivity = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/joinActivity`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      // let value = {
      //   page: 0,
      //   type: "user",
      //   limit: 10
      // }
      // dispatch(getLeaderBoard(value))
      if (callback) {
        callback(true, 'Applied successfully')
      }
    } else {
      if (callback) {
        callback(false, res.data.message)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getActivityDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getActivity/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "activity_details", value: res.data.activities }))
      dispatch(updateRedux({ key: "activity_is_joined", value: res.data.is_joined }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeInitiatives = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getInitiatives`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "initiativesHome", value: res.data.initiatives }))
      dispatch(updateRedux({ key: "initiativesPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllInitiatives = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllInitiatives`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "allInitiatives", value: res.data.initiatives }))
      dispatch(updateRedux({ key: "allInitiativesTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "initiativesPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getInitiativeDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getInitiative/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "intiative_details", value: res.data.initiative }))
      dispatch(updateRedux({ key: "initiativesPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(res.data)
    } else {
      callback && callback(res.data)
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllFAQ = (data, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getAllFaq`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "faq_list", value: res.data.faq_list }))
      dispatch(updateRedux({ key: "allFaqTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHomeFAQ = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getHomeFaq`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "faq_home", value: res.data.faq_list }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getDashboardItems = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getDashboardItems`).then((res) => {
    if (res.data.status) {
      if (callback) callback(true)
      dispatch(updateRedux({ key: "dashboard_list", value: res.data.dashboarditems_details }))
      dispatch(updateRedux({ key: "webLogoPath", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getGalleryHome = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getHomeGallery`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "gallery_list", value: res.data.gallery_list }))
      dispatch(updateRedux({ key: "gallery_Path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllGallery = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllGallery`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_gallery_list", value: res.data.gallery_list }))
      dispatch(updateRedux({ key: "gallery_Path", value: res.data.path }))
      dispatch(updateRedux({ key: "allGalleryTotalPages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getLeaderBoard = (formData, callback) => (dispatch) => {
  console.log(formData)
  axiosFormInstance.post(`${API_BASE_URL}web/getLeaderboard`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "leaderboard_details", value: res.data.leaderboard_details }))
      dispatch(updateRedux({ key: "current_user_leaderboard_details", value: res.data.current_user_leaderboard_details }))
      dispatch(updateRedux({ key: "leaderboardTotalpages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getEvaluationHome = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getHomeEvaluations`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "home_evaluations", value: res.data.evaluations }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAllEvaluation = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAllEvaluations`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "evaluations_list", value: res.data.evaluations }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getEvaluationDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getEvaluationDetail/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "evaluation_details", value: res.data.evaluation_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const closeEvaluation = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/closeEvaluation`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback()
      }
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getSearchData = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}web/applyFilter`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "homeNewsList", value: res.data.news_list }))
      dispatch(updateRedux({ key: "newsPath", value: res.data.news_path }))
      dispatch(updateRedux({ key: "polls_list", value: res.data.survey_list }))
      dispatch(updateRedux({ key: "homeAnnouncementList", value: res.data.announcements_list }))
      dispatch(updateRedux({ key: "announcementPath", value: res.data.announcements_path }))
      dispatch(updateRedux({ key: "homeEventList", value: res.data.events_list }))
      dispatch(updateRedux({ key: "eventPath", value: res.data.events_path }))
      dispatch(updateRedux({ key: "homeTrainingList", value: res.data.training_list }))
      dispatch(updateRedux({ key: "homeScrollList", value: res.data.notifications_list }))
      dispatch(updateRedux({ key: "vacancy_list", value: res.data.vecancy_list }))
      dispatch(updateRedux({ key: "benefit_list", value: res.data.benefits_list }))
      dispatch(updateRedux({ key: "activitiesHome", value: res.data.activities_list }))
      dispatch(updateRedux({ key: "initiativesHome", value: res.data.initiatives_list }))
      dispatch(updateRedux({ key: "home_evaluations", value: res.data.evaluation_list }))
      dispatch(updateRedux({ key: "discussion_list", value: res.data.discussions_list }))
      // dispatch(updateRedux({ key: "pollsCount", value: res.data.pollsCount }))
      // dispatch(updateRedux({ key: "trainingPath", value: res.data.path }))
      // dispatch(updateRedux({ key: "alert_path", value: res.data.path }))
      // dispatch(updateRedux({ key: "quick_access_list", value: res.data.quick_access_list }))
      // dispatch(updateRedux({ key: "quick_access_path", value: res.data.path }))
      // dispatch(updateRedux({ key: "faq_list", value: res.data.faq_list }))
      // dispatch(updateRedux({ key: "dashboard_list", value: res.data.dashboarditems_details }))
      // dispatch(updateRedux({ key: "webLogoPath", value: res.data.path }))
      // dispatch(updateRedux({ key: "gallery_list", value: res.data.gallery_list }))
      // dispatch(updateRedux({ key: "gallery_Path", value: res.data.path }))
      // dispatch(updateRedux({ key: "leaderboard_details", value: res.data.leaderboard_details }))
      // dispatch(updateRedux({ key: "current_user_leaderboard_details", value: res.data.current_user_leaderboard_details }))
      // dispatch(updateRedux({ key: "discussion_path", value: res.data.path }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(res.data.status)
      }

    } else {
      console.log(res.data.message)
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getModules = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getAllModules`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "modules", value: res.data.modules }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getSearchAll = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}web/searchAll`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "searchResult", value: res.data.data }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      if (callback) {
        callback(res.data.status)
      }

    } else {
      console.log(res.data.message)
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getAbout = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getAbout`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "about", value: res.data.about_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getAboutDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getAboutDetail/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "about_details", value: res.data.about_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getRule = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/getRules`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "rules", value: res.data.rules_details }))
      dispatch(updateRedux({ key: "rulePath", value: res.data.path }))
      dispatch(updateRedux({ key: "rulePages", value: res.data.pages }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getRuleDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getRulesDetail/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "rules_details", value: res.data.rules_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getHappinessStatus = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/isHappinessMeasuredToday`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const updateHappiness = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}web/addHappinessMeasuredToday`, data).then((res) => {
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "happiness_status", value: true }))
      // dispatch(updateRedux({ key: "happiness", value: res.data.happiness }))
      // dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      dispatch(getHappinessStatus())
      if (callback) {
        callback()
      }
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}

export const getMenuText = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getTexts`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "words", value: Object.fromEntries(res.data.translate_details.map(obj => [obj.title, obj.english_text])) }))
      dispatch(updateRedux({ key: "translate_details", value: res.data.translate_details }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
      callback && callback(true)
    } else {
      console.log(res.data.message)
      callback && callback(false)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getLeaderBoardModules = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getLeaderBoardModules`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "leaderboard_modules", value: res.data.modules }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserUnitsForLeaderBoard = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}web/getUserUnitsForLeaderBoard`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "leaderboardUserUnitLists", value: res.data.units }))
      dispatch(updateRedux({ key: "happiness_status", value: res.data.isHappinesMeasured }))
    } else {
      console.log(res.data.message)
    }
  }).catch((err) => {
    console.log(err.response)
  })
}
