import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollTop";
import RouteHandler from "./router/routes";
import "./Styles/Responsive.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactWaterMark from "react-watermark-component";
import { useDispatch, useSelector } from "react-redux";
import { employee_id, username } from "./redux/commonReducer";
import { getMenuText } from "./redux/actionCreator";

const options = {
  chunkWidth: 200,
  chunkHeight: 60,
  textAlign: "left",
  textBaseline: "bottom",
  globalAlpha: 0.17,
  rotateAngle: -0.26,
  fillStyle: "#666",
};


function App() {
  const [text, setText] = useState("");
  const emp_id = useSelector(employee_id);
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)

  // useEffect(() => {
  //   setLoader(true)
  //   dispatch(getMenuText((status) => {
  //     setLoader(status ? false : 'error')
  //   }));
  // }, [])

  useEffect(() => {
    dispatch(getMenuText())
    if (localStorage.getItem("employee_id")) {
      setText(localStorage.getItem("employee_id"));
    } else {
      setText("");
    }
  }, [localStorage.getItem("employee_id"), emp_id]);

  return (
    (
      loader === 'error'
        ? <div className="errorLoader"></div>
        : loader
          ? <div id="fullLoader"></div>
          : <>
            {
              <HashRouter>
                <ToastContainer />
                <ScrollToTop />
                {text ? (
                  <ReactWaterMark
                    waterMarkText={text ? text : ""}
                    openSecurityDefense
                    // securityAlarm={beginAlarm}
                    options={options}
                  >
                    <RouteHandler />
                  </ReactWaterMark>
                ) : (
                  <RouteHandler />
                )}
              </HashRouter>
            }
          </>
    )
  );
}

export default App;
